<template>
  <!--
    class="upload-demo": 给上传组件一个类名，用于样式定制。
    action: 这个属性通常用于指定上传的 URL，但在这里它是空的，因为使用了 :http-request 自定义了上传行为。
    :on-remove="handleRemove": 当文件列表中的文件被移除时，调用 handleRemove 方法。
    multiple: 允许用户选择多个文件上传。
    :show-file-list="isShowList": 根据 isShowList 的值决定是否显示已选择文件列表。
    :file-list="loadFileList": 绑定已选择的文件列表到 loadFileList。
    :before-upload="beforeUpload": 在文件上传前调用 beforeUpload 方法进行文件检查或其他操作。
    :http-request="upload": 使用自定义的 upload 方法来上传文件，而不是默认的上传行为。
  -->
  <div>
    <el-upload
      class="upload-demo"
      action
      :on-remove="handleRemove"
      multiple
      :show-file-list="isShowList"
      :file-list="loadFileList"
      :before-upload="beforeUpload"
      :http-request="upload"
    >
      <el-button>
        数据上传
        <i class="el-icon-upload2" />
      </el-button>
    </el-upload>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>上传中,请稍后</span>
    </el-dialog>
  </div>
</template>
<script>
import { uploadImportCompany } from "@/api/enterprise";
import { uploadImportProjectData } from "@/api/numberProject";
import { uploadImportCase } from "@/api/caseData";
import { uploadProduct } from "@/api/product";
import { uploadScheme } from "@/api/scheme";
import { addIndustry } from "@/api/industry";
import { technology } from "@/api/technology";
import { importActivity } from "@/api/activity";
import { field } from "@/api/field";
//policyImportProject 政策数据
import { importExcelV1 } from "@/api/policy";
import { uploadInfo } from "@/api/paper";
import { importExcel } from "@/api/recruit.js";
import { importCommercial } from "@/api/commercial.js";
import { importClientEnterprise } from "@/api/customCompany";
import { importExcelZj } from "@/api/expert";
import {
  importCountryStandard,
  importBehaviorStandard,
  importLocalStandard,
  importCompanyStandard,
  importTeamStandard,
  importInternationalStandard,
  importForeignStandard,
} from "@/api/standardKnowledge";
//getKnowTechList  getKnowFieldList getDicRadioButton
import {
  getIndustryList
} from "@/api/common";
export default {
  props: {
    // 用于传递已选择的文件列表。
    loadFileList: {
      type: Array,
    },
    // 用于控制是否显示文件列表
    isShowList: {
      type: Boolean,
      default: false,
    },
    // 用于传递模块名称（默认为“企业数据”）
    moduleName: {
      type: String,
      default: "企业数据",
    },
  },
  data() {
    return {
      dialogVisible: false, //控制对话框的显示与隐藏
      fileArray: [], //可能在组件内部用于处理文件数据
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  methods: {
    // handleRemove 方法接收两个参数：file 和 fileList。其中，file 是被移除的文件对象，fileList 是当前的文件列表。但在这段代码片段中，handleRemove 方法的主体是空的，意味着当你从文件列表中移除一个文件时，没有任何特定的操作会被执行。你可能需要在这个方法内部添加一些逻辑来处理文件移除后的操作，比如更新显示的文件列表等。
    handleRemove(file, fileList) {},

    // upload 方法是一个异步方法，用于处理文件上传的逻辑。以下是该方法的主要步骤：
    // 设置对话框为可见：this.dialogVisible = true; 这行代码将使得 <el-dialog> 组件变为可见状态，显示“上传中,请稍后”的提示信息。
    // 触发自定义事件：this.$emit("fileClicks"); 这行代码触发了一个名为 fileClicks 的自定义事件，可能用于通知父组件或其他监听者文件上传的开始。
    // 创建 FormData 对象：const form = new FormData(); 创建一个新的 FormData 对象，该对象用于存储要发送的数据。
    // 根据 moduleName 附加文件和其他数据：
    // 如果 moduleName 是 "行业知识" 或 "论文数据"，则将文件附加为 files 字段，并将认证令牌（Authorization）附加为另一个字段。
    // 否则，将文件附加为 file 字段，并将认证令牌附加为另一个字段。
    // 根据 moduleName 调用相应的 API 方法：
    // 使用 switch 语句根据 moduleName 的值选择调用哪个 API 方法来上传文件。
    // 每个 case 语句内部都调用了相应的 API 方法，并等待其返回结果（使用 await）。
    // 如果 moduleName 是 "企业数据"，并且上传成功（res.code == 200），则显示一个成功的消息。
    // 缺失的部分
    // 注意，upload 方法的最后一部分被截断了。你需要在每个 case 语句后面添加适当的逻辑来处理 API 方法的返回值（例如显示错误消息或更新状态）。此外，你可能还希望在文件上传完成后将对话框设置为不可见（this.dialogVisible = false;）。
    // 这段代码定义了一个 Vue 组件，该组件包含一个文件上传功能。当用户选择一个或多个文件并触发上传时，组件会根据 moduleName 的值调用相应的 API 方法来上传文件，并在上传过程中显示一个对话框来提示用户。然而，代码中的 handleRemove 方法是空的，你可能需要添加一些逻辑来处理文件移除后的操作。同时，你也需要完善 upload 方法中的错误处理和上传完成后的逻辑。
    async upload(file) {
      this.dialogVisible = true;
      this.$emit("fileClicks");
      const form = new FormData();

      if (this.moduleName == "行业知识" || this.moduleName == "论文数据") {
        form.append("files", file.file);

        form.append("Authorization", this.Authorization);
      } else {
        form.append("file", file.file);

        form.append("Authorization", this.Authorization);
      }

      let res;
      // 根据 moduleName 的值调用不同的 API 方法来上传文件
      switch (this.moduleName) {
        case "企业数据":
          res = await uploadImportCompany(form);
          if (res.code === 200) {
            this.$message.success("文件上传成功");
          }
          break;

        case "项目数据":
          res = await uploadImportProjectData(form);
          break;

        case "产品数据":
          res = await uploadProduct(form);
          break;

        case "招聘数据":
          res = await importExcel(form);
          break;

        case "案例数据":
          res = await uploadImportCase(form);
          break;

        case "技术知识":
          res = await technology(form);
          break;

        case "领域知识":
          res = await field(form);
          break;

        case "活动数据":
          res = await importActivity(form);
          break;

        // case "政策数据":
        //   res = await policyImportProject(form);
        //   break;

        case "政策数据":
          res = await importExcelV1(form);
          break;

        case "方案拆解":
          res = await uploadScheme(form);
          break;

        case "客户企业":
          res = await importClientEnterprise(form);
          break;

        case "专家数据":
          res = await importExcelZj(form);
          break;

        case "国家标准数据":
          res = await importCountryStandard(form);
          break;

        case "行业标准数据":
          res = await importBehaviorStandard(form);
          break;

        case "地方标准数据":
          res = await importLocalStandard(form);
          break;

        case "团体标准数据":
          res = await importTeamStandard(form);
          break;

        case "企业标准数据":
          res = await importCompanyStandard(form);
          break;

        case "国际标准数据":
          res = await importInternationalStandard(form);
          break;

        case "国外标准数据":
          res = await importForeignStandard(form);
          break;

        case "论文数据":
          res = await uploadInfo(form);
          break;

        case "政府数据":
          res = await importCommercial(form);
          break;

        case "行业知识":
          res = await addIndustry(form);
          this.$emit("fileClick");
          this.$confirm(res.msg, "提示", {
            dangerouslyUseHTMLString: true,
            customClass: "message-logout",
          })
            .then(() => {
              this.dialogVisible = false;
              this.$emit("fileClick");
            })
            .catch(() => {});
          const res1 = await getIndustryList();
          res1.data = JSON.parse(
            JSON.stringify(res1.data).replace(/industryName/g, "codeName")
          );
          sessionStorage.setItem("industry", JSON.stringify(res1.data));
          break;

        default:
          break;
      }

      if (res.code === 415 || res.code === 200) {
        this.dialogVisible = false;
        this.$confirm(res.msg, "提示", {
          dangerouslyUseHTMLString: true,
          customClass: "message-logout",
        })
          .then(() => {
            this.$emit("fileClick");
          })
          .catch(() => {});
      } else if (res.code === 500) {
        this.dialogVisible = false;
        this.$confirm(res.msg, "提示", {
          dangerouslyUseHTMLString: true,
          customClass: "message-logout",
        }).catch(() => {});
      }
    },

    // 这是一个在文件上传前触发的钩子函数，用于检查文件类型和（被注释掉的）大小是否符合要求。
    // 如果文件类型不在指定的列表内（如 .doc, .docx, .xls, .xlsx, .pdf），则显示错误消息并阻止文件上传。
    beforeUpload(file) {
      let FileExt = file.name.replace(/.+\./, "");
      let flag = ["doc", "docx", "xls", "xlsx", "pdf"].includes(FileExt);
      // const isLt5M = file.size / 1024 / 1024 < 5
      if (!flag) this.$message.error("文件格式错误请重新上传!");
      // if (!isLt5M) this.$message.error('上传的文件大小不能超过5MB!')
      return flag;
    },
  },
};
</script>
<style lang="less" scoped>
.iconjr-icon-link {
  font-size: 12px;
}
.the-active {
  .el-button {
    color: #4da75b !important;
  }
  i {
    color: #4da75b !important;
  }
}
.jr-iconjr-icon-upload {
  font-size: 14px;
  // vertical-align: middle !important;
  // line-height: 34px;
}
</style>

<style lang="less">
.message-logout {
  .el-message-box__message p {
    margin: 0;
    line-height: 24px;
    max-height: 300px !important;
    overflow: auto;
  }
  .el-message-box__container {
    max-height: 300px !important;
    overflow: auto;
  }
}
</style>
